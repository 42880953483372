// 土壤墒情
import DJZT from './sn-table-column-djzt';
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [{
                label: "设备",
                prop: "SBMC",
                minWidth: 120
            }, {
                label: "PH值",
                prop: "PH"
            }, {
                label: "温度(℃)",
                prop: "WD"
            }, {
                label: "水分(%)",
                prop: "SF"
            },
            {
                label: "电导率(S/m)",
                prop: "DDL"
            },
            {
                label: "监测时间",
                prop: "JCSJ",
                minWidth: 100
            },
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        dateData : [{
            isShow: true,
            label: '开始时间',
            clearable: true,
            placeholder: '开始时间',
            operateType: 'search',
            value: 'start',
        },{
            isShow: true,
            label: '结束时间',
            clearable: true,
            placeholder: '结束时间',
            operateType: 'search',
            value: 'end',
        }]
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;